import React, {useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Link, Route, Routes,
    useNavigate,
    Navigate,
    useParams,
} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmailIcon from '@mui/icons-material/Email';
import MenuItem from '@mui/material/MenuItem'
import QuizIcon from '@mui/icons-material/Quiz';
import DownloadIcon from '@mui/icons-material/Download';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';

export default function ItogQuiz(props) {

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const names = [
            "q1-1",
            "q1-2-1",
            "q1-2-2",
            "q1-2-3",
            "q1-2-4",
            "q1-2-5",
            "q1-3-1",
            "q1-3-2",
            "q1-3-3",
            "q1-3-4",
            "q1-3-5",
            "q1-4-1",
            "q1-4-2",
            "q1-4-3",
            "q1-4-4",
            "q1-4-5",
            "q1-5-1",
            "q1-5-2",
            "q1-5-3",
            "q1-5-4",
            "q1-5-5",
            "q1-6",
            "q1-7",
            "q1-8-1",
            "q1-8-2",
            "q1-8-3",
            "q1-8-4",
            "q1-8-5",
            "q1-9",
            "q1-10-1",
            "q1-10-2",
            "q1-10-3",
            "q1-10-4",
            "q1-10-5",
            "q1-11",
            "q2-1",
            "q2-2",
            "q2-3",
            "q2-4",
            "q2-5",
            "q2-6",
            "q2-7",
            "q3-1",
            "q3-2",
            "q3-3-1",
            "q3-3-2",
            "q3-3-3",
            "q3-3-4",
            "q3-3-5",
            "q3-4-1",
            "q3-4-2",
            "q3-4-3",
            "q3-4-4",
            "q3-4-5",
            "q3-5",
            "q3-6-1",
            "q3-6-2",
            "q3-6-3",
            "q3-6-4",
            "q3-6-5",
            "q3-7-1",
            "q3-7-2",
            "q3-7-3",
            "q3-7-4",
            "q3-7-5",
            "q3-8-1",
            "q3-8-2",
            "q3-8-3",
            "q3-8-4",
            "q3-8-5",
            "q3-9-1",
            "q3-9-2",
            "q3-9-3",
            "q3-9-4",
            "q3-9-5",
            "q3-10-1",
            "q3-10-2",
            "q3-10-3",
            "q3-10-4",
            "q3-10-5",
            "q3-11-1",
            "q3-11-2",
            "q3-11-3",
            "q3-11-4",
            "q3-11-5",
        ];
        let qResult = [];
        names.forEach((n) => {
            console.log(data.get(n));
            if(n.split("-").length >2){
                qResult.push(!!data.get(n) ? 1 : 0);
            } else {
                if (!!data.get(n)) {
                    for (var i = 1; i < data.get(n); i++) {
                        qResult.push(0);
                    }
                    qResult.push(1);
                    for (var i = data.get(n); i < 5; i++) {
                        qResult.push(0);
                    }
                }else{
                    for (var i = 0; i < 5; i++) {
                        qResult.push(0);
                    }
                }
            }
        });


        console.log(qResult);
        axios.post(`/qjson/submitQuiz`, {
            id: params.id,
            qresult: qResult,
            msg: data.get("q4-1")
        }).then((res) => {
            if (res.data.status === "ok") {
                setDisabled(true);
                props.showSnackbar("Спасибо!", "success");
            } else {
                props.showSnackbar("Ошибка", "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        });
    };

    const checkProgress = async () => {
        try {
            let res = await axios.post(`/qjson/isComplite`, {
                id: params.id,
            });
            if (!!res.data.status && res.data.status === "ok") {
                if (res.data.done) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
            } else {
                props.showSnackbar("Ошибка проверки", "error");
                setDisabled(true);
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        checkProgress();
    }, []);

    const params = useParams();
    const [disabled, setDisabled] = useState(true);

    return (
        <>
            <Container component="main" >
                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h4" gutterBottom component="div">
                        Анкетирование
                    </Typography>
                    <Typography variant="h6" gutterBottom component="div">
                        Уважаемый студент!
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        Данная анкета необходима для оценки качества образования в университете. Полученная информация будет использована для совершенствования учебного процесса, улучшения инфраструктуры университета, повышения качества воспитательной работы. Ответьте, пожалуйста, максимально объективно на все поставленные вопросы. Обращаем Ваше внимание на тот факт, что <b>данная анкета является анонимной.</b>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <FormControl>
                        <FormLabel >1.1. Оцените качество проведения практик (учебных, ознакомительных, производственных, преддипломных и т.д.) в университете.</FormLabel>
                        <RadioGroup
                            id="q1-1"
                            name="q1-1"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Практики, в которых я принимал участие, были абсолютно бесполезны для меня и для результатов обучения в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Практики, в которых я принимал участие, принесли мне незначительную пользу." />
                            <FormControlLabel value="3" control={<Radio />} label="Практики, в которых я принимал участие, были в целом полезны для меня и для результатов обучения в университете." />
                            <FormControlLabel value="4" control={<Radio />} label="Практики, в которых я принимал участие, были полезны для меня и для результатов обучения в университете." />
                            <FormControlLabel value="5" control={<Radio />} label="Практики, в которых я принимал участие, были исключительно полезны для меня и для результатов обучения в университете." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q1-2">1.2.Оцените качество организации практик (учебных, ознакомительных, производственных, преддипломных и т.д.) в университете:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q1-2-1"} />} label="Руководитель практики помог мне найти место прохождения практики" />
                            <FormControlLabel control={<Checkbox name={"q1-2-2"} />} label="Руководитель практики оказывал мне помощь в оформлении документов по итогам практики" />
                            <FormControlLabel control={<Checkbox name={"q1-2-3"} />} label="Я проходил практику в организации, деятельность которой совпадала с моей специальностью" />
                            <FormControlLabel control={<Checkbox name={"q1-2-4"} />} label="Прохождение практики дало мне настоящие навыки и  практический опыт" />
                            <FormControlLabel control={<Checkbox name={"q1-2-5"} />} label="Планирую пройти практику в той же организации, поскольку мной заинтересовались как потенциальным сотрудником" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q1-3">1.3.Оцените использование преподавателями современных образовательных технологий:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q1-3-1"} />} label="Преподаватели используют при проведении занятий мультимедийное оборудование" />
                            <FormControlLabel control={<Checkbox name={"q1-3-2"} />} label="Преподаватели используют при проведении занятий раздаточный материал (рабочие тетради и т.д.)" />
                            <FormControlLabel control={<Checkbox name={"q1-3-3"} />} label="Преподаватели используют при проведении занятий интерактивные формы работы (деловые игры, решение ситуационных задач и т.д.)" />
                            <FormControlLabel control={<Checkbox name={"q1-3-4"} />} label="Преподаватели советуют необходимую литературу, подсказывают информационные ресурсы для получения необходимой информации" />
                            <FormControlLabel control={<Checkbox name={"q1-3-5"} />} label="Преподаватели применяют технологии сбора обратной связи, используют отзывы обучающихся для совершенствования своей техники преподавания" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q1-4">1.4.Оцените степень доступности изложения материала и взаимодействия преподавателя с обучающимися:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q1-4-1"} />} label="Преподаватели оказывают консультационную помощь студентам во внеучебное время" />
                            <FormControlLabel control={<Checkbox name={"q1-4-2"} />} label="Преподаватели дают практические примеры к преподаваемому теоретическому материалу" />
                            <FormControlLabel control={<Checkbox name={"q1-4-3"} />} label="Преподаватели дают материал в форме, которая хорошо и полностью усваивается" />
                            <FormControlLabel control={<Checkbox name={"q1-4-4"} />} label="Я успевал(а) фиксировать всю необходимую мне информацию на лекционных занятиях" />
                            <FormControlLabel control={<Checkbox name={"q1-4-5"} />} label="Практические занятия дают конкретные умения и навыки, которые я должен обрести по итогам освоения дисциплины" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q1-5">1.5.Оцените профессионализм профессорско-преподавательского состава университета:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q1-5-1"} />} label="Преподаватели владеют своим предметом, знают его специфику и особенности" />
                            <FormControlLabel control={<Checkbox name={"q1-5-2"} />} label="Преподаватели имеют профессиональный внешний вид, одеваются соответствующим образом" />
                            <FormControlLabel control={<Checkbox name={"q1-5-3"} />} label="Преподаватели вежливы и корректны с обучающимися" />
                            <FormControlLabel control={<Checkbox name={"q1-5-4"} />} label="Преподаватели не опаздывают на занятия" />
                            <FormControlLabel control={<Checkbox name={"q1-5-5"} />} label="Преподаватели имеют грамотно поставленную речь" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >1.6.Оцените, насколько вы уверены в актуальности полученных в ходе обучения в университете знани</FormLabel>
                        <RadioGroup
                            id="q1-6"
                            name="q1-6"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Знания, которые я получил, неактуальны. Я не уверен, что они будут полезны в моей дальнейшей профессиональной деятельности." />
                            <FormControlLabel value="2" control={<Radio />} label="Знания, которые я получил, имеют незначительную степень актуальности." />
                            <FormControlLabel value="3" control={<Radio />} label="Знания, которые я получил, в целом актуальности." />
                            <FormControlLabel value="4" control={<Radio />} label="Знания, которые я получил, по большей части актуальны." />
                            <FormControlLabel value="5" control={<Radio />} label="Знания, которые я получил, актуальны. Я полностью уверен, что они будут полезны в моей дальнейшей профессиональной деятельности." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >1.7.Оцените объективность контроля качества Ваших знаний, получаемых в ходе обучения в университете.</FormLabel>
                        <RadioGroup
                            id="q1-7"
                            name="q1-7"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Знания оцениваются необъективно, оценка зависит от субъективных факторов и личного отношения преподавателя ко мне." />
                            <FormControlLabel value="2" control={<Radio />} label="Знания оцениваются по большей части необъективно за исключением ряда преподавателей." />
                            <FormControlLabel value="3" control={<Radio />} label="Знания оцениваются в целом объективно." />
                            <FormControlLabel value="4" control={<Radio />} label="Знания оцениваются по большей мере объективно за исключением ряда преподавателей." />
                            <FormControlLabel value="5" control={<Radio />} label="Знания оцениваются полностью объективно и на основании моих реальных знаний." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q1-8">1.8.Оцените, насколько университет обеспечивает Ваш доступ к необходимым для обучения материалам:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q1-8-1"} />} label="В библиотеке университета всегда можно найти необходимый для обучения материал" />
                            <FormControlLabel control={<Checkbox name={"q1-8-2"} />} label="В библиотеке университета имеются актуальные научные периодические издания (журналы, бюллетени и т.д.)" />
                            <FormControlLabel control={<Checkbox name={"q1-8-3"} />} label="В университете есть доступ к электронным библиотекам и базам данных" />
                            <FormControlLabel control={<Checkbox name={"q1-8-4"} />} label="Университет обеспечивает возможность работать с иностранными источниками информации" />
                            <FormControlLabel control={<Checkbox name={"q1-8-5"} />} label="Университет обеспечивает достаточную по времени возможность использования коллективных пунктов доступа к Интернету" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >1.9.Оцените свою осведомленность об образовательной программе, по которой Вы обучались (перечень и структура дисциплин, учебный план и т.д.).</FormLabel>
                        <RadioGroup
                            id="q1-9"
                            name="q1-9"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я не интересуюсь составом своей образовательной программы." />
                            <FormControlLabel value="2" control={<Radio />} label="Я имею общее представление о своей образовательной программе и мне не нужна дополнительная информация." />
                            <FormControlLabel value="3" control={<Radio />} label="Я в целом осведомлен о своей образовательной программе" />
                            <FormControlLabel value="4" control={<Radio />} label="Я хорошо осведомлен о своей образовательной программе, но не знаю, где можно получить дополнительную информацию." />
                            <FormControlLabel value="5" control={<Radio />} label="Я хорошо осведомлен о своей образовательной программе и знаю, где можно получить дополнительную информацию." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q1-10">1.10.Оцените свою возможность влиять на структуру и качество образовательной программы:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q1-10-1"} />} label="У меня была возможность выбирать предметы (часть предметов), по которым я хотел бы обучаться" />
                            <FormControlLabel control={<Checkbox name={"q1-10-2"} />} label="Образовательная организация обеспечивает возможность получения дополнительных знаний по тем предметам, которые меня интересуют" />
                            <FormControlLabel control={<Checkbox name={"q1-10-3"} />} label="Я принимал участие в мониторинге качества образовательных услуг и проходил соответствующее анкетирование" />
                            <FormControlLabel control={<Checkbox name={"q1-10-4"} />} label="Я принимал участие в составлении рейтинга преподавателей" />
                            <FormControlLabel control={<Checkbox name={"q1-10-5"} />} label="В университете есть специалисты, к которым можно обратиться за консультацией при формировании своей индивидуальной образовательной траектории" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >1.11.Оцените созданные в университете условия для занятий научно-инновационной деятельностью.</FormLabel>
                        <RadioGroup
                            id="q1-11"
                            name="q1-11"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я не занимаюсь научно-инновационной деятельностью." />
                            <FormControlLabel value="2" control={<Radio />} label="Моя научно-инновационная деятельность ограничивается участием в студенческих научно-практических конференциях." />
                            <FormControlLabel value="3" control={<Radio />} label="В университете достаточные условия для занятий научно-инновационной деятельностью." />
                            <FormControlLabel value="4" control={<Radio />} label="В университете созданы хорошие условия для занятий научно-инновационной деятельностью." />
                            <FormControlLabel value="5" control={<Radio />} label="В университете созданы все условия для занятий научно-инновационной деятельностью, включая возможность коммерциализировать свои разработки." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.1.Оцените количество мероприятий по воспитательной работе, которые проводятся в университете.</FormLabel>
                        <RadioGroup
                            id="q2-1"
                            name="q2-1"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я совершенно не интересуюсь тем, какая воспитательная работа проводится в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Мероприятия в университете практически не проводятся." />
                            <FormControlLabel value="3" control={<Radio />} label="Проводится не так много мероприятий, но для меня этот выбор достаточен." />
                            <FormControlLabel value="4" control={<Radio />} label="Проводится достаточное число мероприятий. Я всегда могу выбрать то, что мне интересно." />
                            <FormControlLabel value="5" control={<Radio />} label="Проводится большое число мероприятий. Мне сложно выбрать то, которое мне наиболее интересно." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.2.Оцените качество мероприятий по воспитательной работе, которые проводятся в университете.</FormLabel>
                        <RadioGroup
                            id="q2-2"
                            name="q2-2"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я совершенно не интересуюсь тем, какая воспитательная работа проводится в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Мероприятия в университете проводятся на низком уровне, мне не хочется их посещать." />
                            <FormControlLabel value="3" control={<Radio />} label="Проводится достаточно мероприятий, но среди них не так много качественных и интересных для меня." />
                            <FormControlLabel value="4" control={<Radio />} label="Проводятся качественные и интересные мероприятия. Я всегда могу выбрать то, что мне интересно." />
                            <FormControlLabel value="5" control={<Radio />} label="Проводятся качественные и интересные мероприятия. Мне сложно выбрать то, которое мне наиболее интересно." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.3.Оцените разнообразие мероприятий по воспитательной работе, которые проводятся в университете.</FormLabel>
                        <RadioGroup
                            id="q2-3"
                            name="q2-3"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я совершенно не интересуюсь тем, какая воспитательная работа проводится в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Мероприятия в университете практически не проводятся. Сложно говорить о разнообразии." />
                            <FormControlLabel value="3" control={<Radio />} label="Проводится достаточно мероприятий, но они все в целом однотипны." />
                            <FormControlLabel value="4" control={<Radio />} label="Проводятся разнообразные мероприятия. Я могу выбрать то, что мне интересно." />
                            <FormControlLabel value="5" control={<Radio />} label="Проводится много разнообразных мероприятий. Мне сложно выбрать то, которое мне наиболее интересно." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.4.Оцените, как часто вы принимаете участие в мероприятиях по воспитательной работе, проводимых в университете.</FormLabel>
                        <RadioGroup
                            id="q2-4"
                            name="q2-4"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я совершенно не участвовал в мероприятиях по воспитательной работе, проводимых в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Я иногда участвовал в мероприятиях по воспитательной работе, проводимых в университете, но делал это в основном по указанию администрации." />
                            <FormControlLabel value="3" control={<Radio />} label="Я периодически участвовал в мероприятиях по воспитательной работе, проводимых в университете, и делал это по своей воле." />
                            <FormControlLabel value="4" control={<Radio />} label="Я старался  участвовать в большинстве проводимых мероприятий по воспитательной работе в университете." />
                            <FormControlLabel value="5" control={<Radio />} label="Я с удовольствием участвовал во всех мероприятиях, проводимых в университете." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.5.Оцените, насколько хорошо Вы информированы о реализуемой в Вашей образовательной организации воспитательной (внеучебной) работе.</FormLabel>
                        <RadioGroup
                            id="q2-5"
                            name="q2-5"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я совершенно не интересуюсь тем, какая воспитательная работа проводится в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Я совершенно не знаю о том, какая воспитательная работа проводится в университете." />
                            <FormControlLabel value="3" control={<Radio />} label="Я в целом имею представление о том, какая воспитательная работа реализуется в университете." />
                            <FormControlLabel value="4" control={<Radio />} label="Я достаточно информирован о том, какая воспитательная работа реализуется в университете." />
                            <FormControlLabel value="5" control={<Radio />} label="Я полностью информирован о том, какая воспитательная работа реализуется в университете." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.6.Оцените, насколько доступна информация о проводимой воспитательной работе в университете.</FormLabel>
                        <RadioGroup
                            id="q2-6"
                            name="q2-6"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я совершенно не интересуюсь информацией о воспитательной работе в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Информация в плохом доступе, я не знаю, где можно получить необходимую информацию в университете." />
                            <FormControlLabel value="3" control={<Radio />} label="Информация в среднем доступе. Я в целом имею представление,  где можно получить необходимую информацию в университете." />
                            <FormControlLabel value="4" control={<Radio />} label="Информация в хорошем доступе. Я знаю, где можно получить необходимую информацию в университете." />
                            <FormControlLabel value="5" control={<Radio />} label="Информация в полном доступе. Я всегда знаю, где и у кого можно получить необходимую информацию в университете." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >2.7.Оцените, насколько Вы осведомлены о деятельности совета обучающихся в университете.</FormLabel>
                        <RadioGroup
                            id="q2-7"
                            name="q2-7"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я не знаю о существовании совета обучающихся в университете." />
                            <FormControlLabel value="2" control={<Radio />} label="Я что-то слышал о совете обучающихся в университете, но никогда не сталкивался с его работой." />
                            <FormControlLabel value="3" control={<Radio />} label="Я знаю, что в университете есть совет обучающихся, и проинформирован о его деятельности, но никогда не сталкивался с его работой." />
                            <FormControlLabel value="4" control={<Radio />} label="Я имею достаточно информации о совете обучающихся в университете, мне приходилось сталкиваться с его работой." />
                            <FormControlLabel value="5" control={<Radio />} label="Я имею полную информацию о совете обучающихся, активно участвую в его мероприятиях или в его работе." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >3.1.Оцените комфортность (температура, освещенность, состояние мебели и т.д.) аудиторий для проведения занятий в университете.</FormLabel>
                        <RadioGroup
                            id="q3-1"
                            name="q3-1"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я полностью не удовлетворён качеством аудиторий, подавляющая часть аудиторий не приспособлена для обучения." />
                            <FormControlLabel value="2" control={<Radio />} label="Я частично удовлетворён качеством аудиторий, но очень большая часть аудиторий не приспособлена для обучения." />
                            <FormControlLabel value="3" control={<Radio />} label="В целом удовлетворен качеством аудиторий. Большинство аудиторий приспособлено для обучения." />
                            <FormControlLabel value="4" control={<Radio />} label="Я удовлетворен качеством аудиторий. Почти все аудитории, за небольшими исключениями, приспособлены для обучения." />
                            <FormControlLabel value="5" control={<Radio />} label="Я полностью удовлетворен. Все аудитории приспособлены для обучения." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >3.2.Оцените оснащенность (мультимедийное оборудование, лабораторное оборудование и т.д.) аудиторий/лабораторий для проведения занятий в университете.</FormLabel>
                        <RadioGroup
                            id="q3-2"
                            name="q3-2"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Я полностью не удовлетворён оснащением аудиторий/лабораторий, нужного оборудования почти нет." />
                            <FormControlLabel value="2" control={<Radio />} label="Я полностью удовлетворён оснащением аудиторий/лабораторий, но очень большая часть оборудования устарела или нуждается в замене." />
                            <FormControlLabel value="3" control={<Radio />} label="Я в целом удовлетворён оснащением аудиторий/лабораторий, большинство аудиторий/лабораторий оснащено на нужном уровне." />
                            <FormControlLabel value="4" control={<Radio />} label="Я удовлетворён оснащением аудиторий/лабораторий, почти все аудитории/лаборатории оснащены на нужном уровне." />
                            <FormControlLabel value="5" control={<Radio />} label="Я удовлетворён оснащением аудиторий/лабораторий, все аудитории/лаборатории оснащены на нужном уровне." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-3">3.3.Оцените доступность и качество организации питания в университете:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-3-1"} />} label="В университете есть буфеты, где можно перекусить" />
                            <FormControlLabel control={<Checkbox name={"q3-3-2"} />} label="Качество (свежесть) предлагаемого питания меня удовлетворяет" />
                            <FormControlLabel control={<Checkbox name={"q3-3-3"} />} label="В университете можно приобрести комплексный обед" />
                            <FormControlLabel control={<Checkbox name={"q3-3-4"} />} label="Предлагаемый ассортимент блюд (продуктов питания) достаточно разнообразен и устраивает меня" />
                            <FormControlLabel control={<Checkbox name={"q3-3-5"} />} label="Питание в образовательной организации доступно мне по ценам" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-4">3.4.Оцените соблюдение гигиенических норм в университете:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-4-1"} />} label="В туалетах университета всегда есть мыло, туалетная бумага и т.д." />
                            <FormControlLabel control={<Checkbox name={"q3-4-2"} />} label="Туалеты университета имеют все необходимое оборудования" />
                            <FormControlLabel control={<Checkbox name={"q3-4-3"} />} label="Каждый корпус университета оснащен достаточным числом туалетов" />
                            <FormControlLabel control={<Checkbox name={"q3-4-4"} />} label="В туалетах университета всегда есть горячая вода" />
                            <FormControlLabel control={<Checkbox name={"q3-4-5"} />} label="Туалеты университета поддерживаются в чистоте" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel >3.5.Оцените качество инфраструктуры для занятий спортом и физической культурой в университета.</FormLabel>
                        <RadioGroup
                            id="q3-5"
                            name="q3-5"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Инфраструктура для занятий физической культурой и спортом отсутствует или практически отсутствует." />
                            <FormControlLabel value="2" control={<Radio />} label="Инфраструктура для занятий физической культурой и спортом на минимальном уровне и достаточна только для проведения занятий по физкультуре." />
                            <FormControlLabel value="3" control={<Radio />} label="Инфраструктура для занятий физической культурой и спортом на среднем уровне." />
                            <FormControlLabel value="4" control={<Radio />} label="Инфраструктура для занятий физической культурой и спортом на хорошем уровне. Ее достаточно для проведения занятий по физкультуре, а также есть возможность дополнительных занятий спортом." />
                            <FormControlLabel value="5" control={<Radio />} label="Инфраструктура для занятий физической культурой и спортом достаточна для любых видов спортивной и физической активности." />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-6">3.6.Оцените качество инфраструктуры для занятий творчеством и проведения культурно-массовых мероприятий в университете:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-6-1"} />} label="В университете есть возможности для занятия творчеством" />
                            <FormControlLabel control={<Checkbox name={"q3-6-2"} />} label="Состояние актового зала меня удовлетворяет" />
                            <FormControlLabel control={<Checkbox name={"q3-6-3"} />} label="Актовый зал хорошо оборудован для проведения культурно-массовых мероприятий" />
                            <FormControlLabel control={<Checkbox name={"q3-6-4"} />} label="В образовательной организации есть помещения для занятий творчеством: секции, клубы, студии и т.д." />
                            <FormControlLabel control={<Checkbox name={"q3-6-5"} />} label="Студенты имеют нормальный доступ к помещениям для занятий творчеством" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-7">3.7.Оцените доступ к сети Интернет:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-7-1"} />} label="В университете в учебных корпусах есть точки доступа wi-fi с открытым доступом" />
                            <FormControlLabel control={<Checkbox name={"q3-7-2"} />} label="В университете в общежитии есть точки доступа wi-fi с открытым доступом" />
                            <FormControlLabel control={<Checkbox name={"q3-7-3"} />} label="В библиотеке университета имеются компьютеры с доступом в интернет" />
                            <FormControlLabel control={<Checkbox name={"q3-7-4"} />} label="В библиотеке университета есть доступ к интернет-базам научных ресурсов" />
                            <FormControlLabel control={<Checkbox name={"q3-7-5"} />} label="Скорость доступа в интернет меня удовлетворяет" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-8">3.8.Оцените оснащенность университета компьютерно-вычислительной техникой и необходимым мультимедийным оборудованием</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-8-1"} />} label="В университете достаточно компьютеров для организации учебного процесса" />
                            <FormControlLabel control={<Checkbox name={"q3-8-2"} />} label="На занятиях регулярно используется мультимедийное оборудование" />
                            <FormControlLabel control={<Checkbox name={"q3-8-3"} />} label="Мы имеем возможность использовать компьютерную технику для организации учебного процесса в достаточном объеме времени" />
                            <FormControlLabel control={<Checkbox name={"q3-8-4"} />} label="Используемая компьютерная техника отвечает всем современным требованиям" />
                            <FormControlLabel control={<Checkbox name={"q3-8-5"} />} label="Используемое мультимедийное оснащение отвечает всем современным требованиям" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-9">3.9.Оцените качество инфраструктуры общежития в университете:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-9-1"} />} label="Техническое состояние общежития (состояние здания) меня удовлетворяет" />
                            <FormControlLabel control={<Checkbox name={"q3-9-2"} />} label="Безопасность общежития на должном уровне (в том числе общежитие защищено от доступа посторонних, соблюдаются правила пожарной безопасности и т.д.)" />
                            <FormControlLabel control={<Checkbox name={"q3-9-3"} />} label="В общежитии соблюдаются все санитарные нормы (освещенность, температура, состояния санузлов и т.д.)" />
                            <FormControlLabel control={<Checkbox name={"q3-9-4"} />} label="Общежитие круглосуточно доступно для входа студентов" />
                            <FormControlLabel control={<Checkbox name={"q3-9-5"} />} label="Состояние комнат меня удовлетворяет" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-10">3.10.Оцените состояние учебных корпусов университета:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-10-1"} />} label="Корпус оснащен гардеробом" />
                            <FormControlLabel control={<Checkbox name={"q3-10-2"} />} label="Организована система безопасности корпуса" />
                            <FormControlLabel control={<Checkbox name={"q3-10-3"} />} label="В корпусе есть пространство для ожидания обучающихся в перерывах между занятиями" />
                            <FormControlLabel control={<Checkbox name={"q3-10-4"} />} label="Корпус поддерживается в чистоте и порядке" />
                            <FormControlLabel control={<Checkbox name={"q3-10-5"} />} label="Техническое состояние корпуса меня удовлетворяет" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q3-11">3.11.Оцените удобство и состояние административных помещений университета:</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name={"q3-11-1"} />} label="Приемные/деканаты оснащены стульями и столами для заполнения заявлений и иных документов" />
                            <FormControlLabel control={<Checkbox name={"q3-11-2"} />} label="Оборудованы зоны ожидания для студентов" />
                            <FormControlLabel control={<Checkbox name={"q3-11-3"} />} label="Информационные стенды всегда содержат актуальную информацию" />
                            <FormControlLabel control={<Checkbox name={"q3-11-4"} />} label="Кассы университета имеются в достаточном количестве" />
                            <FormControlLabel control={<Checkbox name={"q3-11-5"} />} label="Административные помещения организованы так, чтобы избежать очередей" />
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="q4-1">4.1. Перечислите, пожалуйста, три основные (наиболее острые) проблемы, которые возникают у Вас как у студента.</FormLabel>
                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={6}
                            id="template"
                            name="q4-1"
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={disabled}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {disabled ? "Благодарим Вас за участие в анкетировании!" : "Отправить"}
                    </Button>
                </Box>
            </Container>
        </>
    )
}