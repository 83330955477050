import React, {useState, useEffect} from 'react';
import './App.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Link, Route, Routes,
    useNavigate,
    Navigate,
    useParams,
} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmailIcon from '@mui/icons-material/Email';
import MenuItem from '@mui/material/MenuItem'
import QuizIcon from '@mui/icons-material/Quiz';
import DownloadIcon from '@mui/icons-material/Download';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ItogQuiz from './ItogQuiz';

const theme= createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#355264',
        },
        secondary: {
            main: '#e4541d',
        },
    },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      <MuiLink color="inherit" href="https://www.ulspu.ru/">
        Анкетирование УлГПУ
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function App(props) {
    let navigate = useNavigate();

  const showSnackbar = (message, severity = "") => {
    setSnackMessage(message);
    setSnackSeverity(severity)
    setSnackOpen(true);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios.post(`/qjson/login`, {
      pwd: data.get('password')
    }).then((res) => {
      if (res.data.status === "ok") {
        setPWD(data.get('password'));
        showSnackbar("Успешный вход", "success");
      } else {
        showSnackbar("Ошибка", "error");
      }
    }).catch((err) => {
      showSnackbar("Ошибка", "error");
      console.log(err);
    })
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
  };

  const uploadFile = async (files) => {
    try {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("pwd", pwd);
      let res = await axios.post(`/qjson/uploadXLSX`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!!res.data.status && res.data.status === "ok") {
        showSnackbar("Файл загружен", "success");
        setRnd(Math.random());
      } else {
        showSnackbar("Ошибка загрузки файла", "error");
        console.log("error")
      }
    } catch (e) {
      props.showSnackbar("Ошибка загрузки файла", "error");
      console.log(e)
    }
  }

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [pwd, setPWD] = React.useState("");
  const [rnd, setRnd] = React.useState(Math.random());


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
        <CssBaseline />
        <Routes>
          <Route path={"/"} element={!!pwd ? <Home showSnackbar={showSnackbar} uploadFile={uploadFile} pwd={pwd} /> : <SignIn showSnackbar={showSnackbar} handleLogin={handleLogin} />} />
          <Route path={"/quizProgress/:id"} element={<QuizProgress showSnackbar={showSnackbar} /> } />
          <Route path={"/quiz/:id"} element={<ItogQuiz showSnackbar={showSnackbar} /> } />
        </Routes>
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
          {snackSeverity !== "" ?
            <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
              {snackMessage}
            </Alert>
            :
            <p>{snackMessage}</p>
          }
        </Snackbar>

      </Box>
    </ThemeProvider>
  );
}

function CountCard(props) {

    const [count, setCount] = useState(0);

    const getCount = async () => {
        try {
            let res = await axios.post(`/qjson/${props.students ? "studentsCnt" : props.groups ? "groupsCnt" : "yearsCnt"}`, {
              pwd: props.pwd
            });
            if (!!res.data.status && res.data.status === "ok") {
                setCount(res.data.count);
            } else {
                console.log("error")
            }
        }catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        getCount();
    },[]);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {props.students ? "Студентов" :
            props.groups ? "Групп" : 
              "Годов"
                }
            </Typography>
            <Typography component="p" variant="h4" sx={{ flex: 1 }}>
                {count}
            </Typography>
        </React.Fragment>
    );
}

function SignIn(props) {

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Вход
        </Typography>
        <Box component="form" onSubmit={props.handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Войти
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

function Home(props) {

  return (
    <Container component="main" >
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <AttachFileIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Загрузка студетнов
        </Typography>
        <Box component="form" onSubmit={props.handleLogin} noValidate sx={{ mt: 1 }}>
          <Button variant={"outlined"} component={"label"} startIcon={<AttachFileIcon />}>
            Загрузить таблицу
            <input type={"file"} hidden accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => props.uploadFile(e.target.files)} />
          </Button>
        </Box>
        <Grid container spacing={2} sx={{mt:2}} >
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <CountCard students  pwd={props.pwd}/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <CountCard groups  pwd={props.pwd}/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <CountCard years pwd={props.pwd} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mt:2}} >
          <Grid item xs={12}>
            <MailTemplate pwd={props.pwd} showSnackbar={props.showSnackbar} name="itog" />
          </Grid>
        </Grid>
        <StartItogTest pwd={props.pwd} showSnackbar={props.showSnackbar} />
        <TestsList pwd={props.pwd} showSnackbar={props.showSnackbar} />
      </Box>
    </Container>
  );
}

function QuizProgress(props) {

  const param = useParams();

  const [progress, setProgress] = useState([]);

  const loadProgress = async () => {
    try {
      let res = await axios.post(`/qjson/getProgress`, {
        id: param.id
      });
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        setProgress(res.data.progress);
      } else {
        props.showSnackbar("Ошибка загрузки прогресса", "error");
        console.log("error")
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadProgress();
  }, []);

  return (
    <Container component="main" >
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <QuizIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Прогресс Анкетирования
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ФИО</TableCell>
                  <TableCell align="right">Прошёл</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {progress.map((student, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {student.fio}
                    </TableCell>
                    <TableCell align="right">{student.done ? "Да" : "Нет"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
}

function MailTemplate(props) {


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    axios.post(`/qjson/saveMailTemplate`, {
      title: data.get('title'),
      name: props.name,
      subject: data.get('subject'),
      template: data.get('template'),
      pwd: props.pwd,
    }).then((res) => {
      if (res.data.status === "ok") {
        props.showSnackbar("Сохранено", "success");
      } else {
        props.showSnackbar("Ошибка", "error");
      }
    }).catch((err) => {
      props.showSnackbar("Ошибка", "error");
      console.log(err);
    })
  };

  const [template, setTemplate] = useState("");
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");


  const loadTemplate = async () => {
    try {
      let res = await axios.post(`/qjson/mailTemplate`, {
        name: props.name,
        pwd: props.pwd
      });
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        setTemplate(res.data.template.template);
        setSubject(res.data.template.subject);
        setTitle(res.data.template.title);
      } else {
        props.showSnackbar("Ошибка загрузки шаблона", "error");
        console.log("error")
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadTemplate();
  }, []);

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <EmailIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Редактировать шаблон
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="title"
              label="Название шаблона"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="name"
              label="Системное имя"
              name="name"
              disabled={true}
              value={props.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="subject"
              label="Тема письма"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={6}
              id="template"
              label="Шаблон письма"
              name="template"
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Сохранить
        </Button>
      </Box>
    </>
  );
}

function StartItogTest(props) {

  const [years, setYears] = useState([]);
  const [year, setYear] = useState("");

  const loadYears = async () => {
    try {
      let res = await axios.post(`/qjson/yearsList`, {
        pwd: props.pwd
      });
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        setYears(res.data.years);
      } else {
        props.showSnackbar("Ошибка загрузки годов", "error");
        console.log("error")
      }
    } catch (e) {
      console.log(e)
    }
  }

  const startTest = async () => {
    try {
      let res = await axios.post(`/qjson/startItogTest`, {
        pwd: props.pwd,
        year: year
      });
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        props.showSnackbar("Анкетирование запущено", "success");
      } else {
        props.showSnackbar("Ошибка запуска анкетирования", "error");
        console.log("error")
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    loadYears();
  }, []);

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <EmailIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Итоговое анкетирование
      </Typography>
      <Box sx={{ mt: 3 }}>
        <TextField
          required
          select
          fullWidth
          id="year"
          label="Год"
          name="year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          {!!years && years.map((opt) => (
            <MenuItem key={opt} value={opt}>{opt}</MenuItem>
          ))}
        </TextField>
        <Button
          fullWidth
          variant="contained"
          color="error"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => { startTest() }}
        >
          Запустить итоговое анкетирование
        </Button>
      </Box>
    </>
  );
}

function TestsList(props) {

  const [tests, setTests] = useState([]);

  const loadTests = async () => {
    try {
      let res = await axios.post(`/qjson/testsList`, {
        pwd: props.pwd
      });
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        setTests(res.data.tests);
      } else {
        props.showSnackbar("Ошибка загрузки анктирований", "error");
        console.log("error")
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getResult = async(id) => {
    axios({
      url: `/qjson/getQuizResults`,
      method: 'POST',
      responseType: 'blob', // important
      data: {
        pwd: props.pwd,
        id: id
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `анкетирование_${id}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  }

  useEffect(() => {
    loadTests();
  }, []);

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <QuizIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Анкетирования
      </Typography>
      <Box sx={{ mt: 3 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Тип</TableCell>
                <TableCell align="right">Год/Предметы</TableCell>
                <TableCell align="right">Прогресс</TableCell>
                <TableCell align="right">Ссылки старостам</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tests.map((test) => (
                <TableRow
                  key={test.test._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {test.test.type === "itog" ? "Итоговый" : "Предметный"}
                    <IconButton aria-label="скачать" onClick={(e)=> getResult(test.test._id)}>
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">{test.test.type === "itog" ? test.test.year : test.test.subject.join(", ")}</TableCell>
                  <TableCell align="right">{test.doneCnt}/{test.allCnt}</TableCell>
                  <TableCell align="right">
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {test.links.map((link) => (
                        <ListItem>
                          <ListItemText primary={link.group} secondary={`https://quiz.ulspu.ru/quizProgress/${link._id}`} />
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
